import kebabCase from "lodash/kebabCase";
import packageJson from "../package";
import MicroElement from "./core";

const elementName = kebabCase(packageJson.name);
if (!customElements.get(elementName)) {
  customElements.define(elementName, MicroElement);
} else {
  console.error(
    `A custom element with the name "${elementName}" already exists in the page. We can't define it again.`
  );
}
