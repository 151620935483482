import de from "./de";
import en from "./en";
import es from "./es";
import esLATAM from "./es-LATAM";
import esUS from "./es-US";
import fr from "./fr";
import frCA from "./fr-CA";
import it from "./it";
import nl from "./nl";
import pt from "./pt";
import ru from "./ru";

export default {
  de: de,
  en: en,
  es: es,
  "es-US": esUS,
  "es-LATAM": esLATAM,
  fr: fr,
  "fr-CA": frCA,
  it: it,
  nl: nl,
  pt: pt,
  ru: ru
};
