const messages = {
  translations: {
    generic: {
      yes: "Yes",
      no: "No",
      done: "Done",
      close: "Close",
      tryAgain: "Try again"
    }
  }
};

export default messages;
