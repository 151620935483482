const messages = {
  translations: {
    generic: {
      yes: "да",
      no: "нет",
      done: "Выполнено",
      close: "закрывать",
      tryAgain: "Попробуйте еще раз"
    }
  }
};

export default messages;
