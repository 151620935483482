import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import defaultTranslations from "./defaults";
import customTranslations from "../../languages";

const translations = {};
const defaultLanguages = Object.keys(defaultTranslations);
const customLanguages = Object.keys(customTranslations);
export const availableLanguages = defaultLanguages
  .concat(customLanguages)
  .filter((x, i, a) => a.indexOf(x) === i);

defaultLanguages.forEach(lang => {
  const mergedTranslations = {
    translations: defaultTranslations[lang].translations
  };

  if (customTranslations.hasOwnProperty(lang)) {
    Object.assign(
      mergedTranslations.translations,
      customTranslations[lang].translations
    );
  }

  translations[lang] = mergedTranslations;
});

customLanguages
  .filter(lang => defaultLanguages.indexOf(lang) === -1)
  .forEach(lang => {
    translations[lang] = {
      translations: customTranslations[lang].translations
    };
  });

i18n.use(initReactI18next).init({
  resources: {
    ...translations
  },
  lng: "en",
  fallbackLng: "en",
  debug: process.env.NODE_ENV === "development" ? true : false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

export default i18n;
