const messages = {
  translations: {
    generic: {
      yes: "Ja",
      no: "Nein",
      done: "Erledigt",
      close: "Schließen",
      tryAgain: "Versuch es noch einmal"
    }
  }
};

export default messages;
