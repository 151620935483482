const messages = {
  translations: {
    generic: {
      yes: "Sim",
      no: "Não",
      done: "Feito",
      close: "Fechar",
      tryAgain: "Tente novamente"
    }
  }
};

export default messages;
