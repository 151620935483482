const messages = {
  translations: {
    generic: {
      yes: "Oui",
      no: "Non",
      done: "Terminé",
      close: "Fermer",
      tryAgain: "Réessayer"
    }
  }
};

export default messages;
