const messages = {
  translations: {
    generic: {
      yes: "Ja",
      no: "Nee",
      done: "Gedaan",
      close: "Sluiten",
      tryAgain: "Probeer het opnieuw"
    }
  }
};

export default messages;
