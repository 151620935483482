const messages = {
  translations: {
    generic: {
      yes: "Si",
      no: "No",
      done: "Hecho",
      close: "Cerrar",
      tryAgain: "Inténtalo de nuevo"
    }
  }
};

export default messages;
